/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    a: "a",
    strong: "strong",
    pre: "pre",
    code: "code"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "Send a POST request to the following endpoint:"), "\n", React.createElement(_components.p, null, "POST ", React.createElement(_components.a, {
    href: "https://api.uat.payroc.com/v1/processing-terminals/%7BprocessingTerminalId%7D/subscriptions/%7BsubscriptionId%7D/pay"
  }, "https://api.uat.payroc.com/v1/processing-terminals/{processingTerminalId}/subscriptions/{subscriptionId}/pay")), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Example response")), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-json"
  }, "{\n\t\"paymentId\": \"KOHM1D3EZH\",\n\t\"processingTerminalId\": \"3204001\",\n\t\"operator\": \"Davi Crisostomo\",\n\t\"order\": {\n\t\t\"orderId\": \"UniqueValueForOrderID\",\n\t\t\"dateTime\": \"2023-06-20T20:25:57.641+01:00\",\n\t\t\"description\": \"Subscription Payment - Manual\",\n\t\t\"amount\": 11000,\n\t\t\"currency\": \"USD\"\n\t},\n\t\"card\": {\n\t\t\"type\": \"Visa Credit\",\n\t\t\"entryMethod\": \"keyed\",\n\t\t\"cardNumber\": \"444433******1111\",\n\t\t\"expiryDate\": \"1226\",\n\t\t\"secureToken\": {\n\t\t\t\"secureTokenId\": \"MREF_1a93f3a6-9029-419f-9e87-3e2db6f0ae85uA\",\n\t\t\t\"customerName\": \"\",\n\t\t\t\"token\": \"2967538502417872\",\n\t\t\t\"status\": \"cvv_validated\",\n\t\t\t\"link\": {\n\t\t\t\t\"rel\": \"self\",\n\t\t\t\t\"method\": \"GET\",\n\t\t\t\t\"href\": \"https://api.uat.payroc.com/v1/processing-terminals/3204001/secure-tokens/MREF_1a93f3a6-9029-419f-9e87-3e2db6f0ae85uA\"\n\t\t\t}\n\t\t},\n\t\t\"securityChecks\": {\n\t\t\t\"cvvResult\": \"M\",\n\t\t\t\"avsResult\": \"Y\"\n\t\t}\n\t},\n\t\"transactionResult\": {\n\t\t\"type\": \"sale\",\n\t\t\"status\": \"ready\",\n\t\t\"approvalCode\": \"OK24225\",\n\t\t\"authorizedAmount\": 11000,\n\t\t\"currency\": \"USD\",\n\t\t\"responseCode\": \"A\",\n\t\t\"responseMessage\": \"OK24225\",\n\t\t\"cardSchemeReferenceId\": \"nkMX2G7ZqPavgbYiYacQ\"\n\t}\n}\n")));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
