/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    a: "a",
    strong: "strong",
    pre: "pre",
    code: "code"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "Send a POST request to the following endpoint:"), "\n", React.createElement(_components.p, null, "POST ", React.createElement(_components.a, {
    href: "https://api.uat.payroc.com/v1/processing-terminals/%7BprocessingTerminalId%7D/payment-plans"
  }, "https://api.uat.payroc.com/v1/processing-terminals/{processingTerminalId}/payment-plans")), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Example response")), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-json"
  }, "{\n\t\"paymentPlanId\": \"PaymentPlanTest\",\n\t\"processingTerminalId\": \"3204001\",\n\t\"name\": \"Davi Crisostomo\",\n\t\"description\": \"Payment Plan creation\",\n\t\"currency\": \"USD\",\n\t\"setupOrder\": {\n\t\t\"amount\": 11000,\n\t\t\"description\": \"Setup Amount\"\n\t},\n\t\"length\": 24,\n\t\"type\": \"manual\",\n\t\"frequency\": \"monthly\",\n\t\"onUpdate\": \"update\",\n\t\"onDelete\": \"complete\"\n}\n\n")));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
