/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    a: "a",
    strong: "strong",
    pre: "pre",
    code: "code"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "Send a POST request to the following endpoint:"), "\n", React.createElement(_components.p, null, "POST ", React.createElement(_components.a, {
    href: "https://api.uat.payroc.com/v1/processing-terminals/%7BprocessingTerminalId%7D/subscriptions"
  }, "https://api.uat.payroc.com/v1/processing-terminals/{processingTerminalId}/subscriptions")), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Example response")), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-json"
  }, "{\n\t\"subscriptionId\": \"SubTest\",\n\t\"processingTerminalId\": \"3204001\",\n\t\"paymentPlan\": {\n\t\t\"paymentPlanId\": \"PaymentPlanTest\",\n\t\t\"name\": \"Davi Crisostomo\",\n\t\t\"link\": {\n\t\t\t\"rel\": \"self\",\n\t\t\t\"method\": \"GET\",\n\t\t\t\"href\": \"https://api.uat.payroc.com/v1/processing-terminals/3204001/payment-plans/PaymentPlanTest\"\n\t\t}\n\t},\n\t\"secureToken\": {\n\t\t\"secureTokenId\": \"MREF_1a93f3a6-9029-419f-9e87-3e2db6f0ae85uA\",\n\t\t\"customerName\": \"\",\n\t\t\"token\": \"2967538502417872\",\n\t\t\"status\": \"cvv_validated\",\n\t\t\"link\": {\n\t\t\t\"rel\": \"self\",\n\t\t\t\"method\": \"GET\",\n\t\t\t\"href\": \"https://api.uat.payroc.com/v1/processing-terminals/3204001/secure-tokens/MREF_1a93f3a6-9029-419f-9e87-3e2db6f0ae85uA\"\n\t\t}\n\t},\n\t\"name\": \"Davi Crisostomo\",\n\t\"description\": \"Create Subscription from ICC payment\",\n\t\"currency\": \"USD\",\n\t\"setupOrder\": {\n\t\t\"orderId\": \"TheOrderID\",\n\t\t\"amount\": 11000,\n\t\t\"description\": \"Desription of SETUPORDER\"\n\t},\n\t\"recurringOrder\": {\n\t\t\"amount\": 11000,\n\t\t\"description\": \"RECURRING Order price\"\n\t},\n\t\"currentState\": {\n\t\t\"status\": \"active\",\n\t\t\"nextDueDate\": \"2023-08-02\",\n\t\t\"paidInvoices\": 0,\n\t\t\"outstandingInvoices\": 122\n\t},\n\t\"startDate\": \"2023-08-02\",\n\t\"endDate\": \"2025-12-02\",\n\t\"length\": 24,\n\t\"type\": \"automatic\",\n\t\"frequency\": \"weekly\",\n\t\"pauseCollectionFor\": 0\n}\n\n")));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
