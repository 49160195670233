/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    a: "a",
    strong: "strong",
    pre: "pre",
    code: "code"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "Send a POST request to the following endpoint:"), "\n", React.createElement(_components.p, null, "POST ", React.createElement(_components.a, {
    href: "https://api.uat.payroc.com/v1/processing-terminals/%7BprocessingTerminalId%7D/secure-tokens"
  }, "https://api.uat.payroc.com/v1/processing-terminals/{processingTerminalId}/secure-tokens")), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Example response")), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-json"
  }, "{\n\t\"secureTokenId\": \"MREF_1a93f3a6-9029-419f-9e87-3e2db6f0ae85uA\",\n\t\"processingTerminalId\": \"3204001\",\n\t\"source\": {\n\t\t\"type\": \"card\",\n\t\t\"cardholderName\": \"\",\n\t\t\"cardNumber\": \"444433******1111\",\n\t\t\"expiryDate\": \"1226\"\n\t},\n\t\"token\": \"2967538502417872\",\n\t\"status\": \"cvv_validated\"\n}\n\n")));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
